<template>
  Error - {{ category }}
</template>

<script>
  export default {
    props: [
      'category',
      'breadcrumb',
      'splitviewMode',
    ]
  }
</script>